import React from 'react'
import flowerlogo from "../images/Group 1320.png";
import vector from "../images/Mask Group 36.png";
import puppy from "../images/Group 106.png";
import logo from "../images/ghmclogo 1.png";
import Brushing from "../images/Vector copy 4.png";
import Bathing from "../images/Vector copy 5.png";
import  blurvector from "../images/Mask Group 37.png"
import  blurvector2 from "../images/Mask Group 38.png"
import "../Gromming puppy/Gpuppy.css"
import Footer from "../Footer/Footer";

export const Gpuppy = () => {
  return (
   <>
       <section>
       <div className="image-overlap">
                <img src={puppy} alt="" className="" />
              </div>
              <div className="vector-img">
                  <img src={vector} alt="" />
                </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="ghmc-logo">
                {/* <img src={logo} alt="ghmc logo" /> */}
              </div>
              <div className="f-logo-bg">
                <img src={flowerlogo} alt="ghmc logo" />
                <img src={flowerlogo} alt="ghmc logo" />
              </div>
              <div className="text-content">
                <h2>Training & Behavior:</h2>
                {/* <hr className="text-border"></hr> */}
                <h1>Puppies and Dogs</h1>
                <p>
                  Nullam accumsan metus quis velit molestie consequat. Mauris
                  laoreet aliquam diam at lobortis. Donec fermentum facilisis
                  vestibulum. Proin vehicula mattis nunc dapibus condimentum.
                  Integer a tortor dapibus, mattis lectus venenatis, pulvinar
                  dui. Duis a iaculis urna.
                </p>
              
              </div>
            </div>

           
          </div>
        </div>
      </section>
      <section className='section-top'>
        <div className='container'>
        <div className="blurvector-img">
                  <img src={ blurvector } alt="" className="blur-img-left" />
                  <img src={ blurvector2 } alt="" className="blur-img" />
                </div>
            <div className='row'>
            <div className='col-lg-6'>
                <div className='puppy-img ml-5'>
                <img src={Brushing} alt=""  className='img-fluid'/>
                </div>
            </div>
            <div className='col-lg-6'>
                <div className='puppy-text'>
                    <h3>Brushing</h3>
                    <p>Aliquam bibendum nec elit vel fringilla. Pellentesque porttitor neque ex, et vehicula turpis sodales at. Sed tempus elementum elementum. Nullam rhoncus mi a dui molestie aliquet. Etiam at arcu vitae ante tristique elementum. Donec ante nisl, eleifend eget sapien vel, molestie porta augue. Proin gravida, tellus eget tempus dignissim, enim ex vestibulum erat, ut sodales tellus purus vel nisi.</p>
                    <p>Duis luctus luctus lacus, eget pharetra sem pellentesque nec. Aenean faucibus feugiat justo non imperdiet. Curabitur quis felis vitae mi pretium semper non eu nunc. Maecenas et nibh turpis. Donec et elit et purus aliquam fermentum.</p>

                </div>

            </div>
            </div>
       <div className='mt-5'>
       <div className='row'>
            <div className='col-lg-6'>
                <div className='puppy-text'>
                    <h3>Bathing</h3>
                    <p>Aliquam bibendum nec elit vel fringilla. Pellentesque porttitor neque ex, et vehicula turpis sodales at. Sed tempus elementum elementum. Nullam rhoncus mi a dui molestie aliquet. Etiam at arcu vitae ante tristique elementum. Donec ante nisl, eleifend eget sapien vel, molestie porta augue. Proin gravida, tellus eget tempus dignissim, enim ex vestibulum erat, ut sodales tellus purus vel nisi.</p>
                    <p>Duis luctus luctus lacus, eget pharetra sem pellentesque nec. Aenean faucibus feugiat justo non imperdiet. Curabitur quis felis vitae mi pretium semper non eu nunc. Maecenas et nibh turpis. Donec et elit et purus aliquam fermentum.</p>

                </div>

            </div>
            <div className='col-lg-6'>
                <div className='puppy-img ml-5'>
                <img src={Bathing} alt=""  className='img-fluid'/>
                </div>
            </div>
            </div>
       </div>
            <div>

            </div>
           
        </div>
      </section>
      <Footer></Footer>
   </>
  )
}
