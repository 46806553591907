import React from "react";
import { useState, useEffect } from "react";
import logo from "../images/ghmclogo 1.png";
import flowerlogo from "../images/Group 1320.png";
import FeedingDog from "../images/Group 111.png";
import vector from "../images/Mask Group 36.png";
import whitedog from "../images/Mask Group 17.png";
import "../Feeding/feedingtable.css";
import Footer from "../Footer/Footer";
import Scroll from "../Backtotop/Scroll";
import  blurvector from "../images/Mask Group 37.png";
import  blurvector2 from "../images/Mask Group 38.png";

const Feedingtable = () => {

  const [data, setData] = useState();
  const Feedingapidata = async () => {
    const response = await fetch(
      `https://api.betterworldforpets.in/api/PetGuidelines/GetPetFeedingGuideline`
    ).then((response) => response.json());

    setData(response);
  };
  useEffect(() => {
    Feedingapidata();
  }, []);

  
  return (
    <>
      <section>
      <div className="image-overlap">
                <img src={FeedingDog} alt="" className="" />
              </div>
              <div className="vector-img">
                  <img src={vector} alt="" />
                </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="ghmc-logo">
                {/* <img src={logo} alt="ghmc logo" /> */}
              </div>
              <div className="f-logo-bg">
                <img src={flowerlogo} alt="ghmc logo" />
                <img src={flowerlogo} alt="ghmc logo" />
              </div>
              <div className="text-content">
                <h2>Feeding :</h2>
                <h1>Guidelines</h1>
                <p>
                  Remember that each dogs is different. As a general guide we
                  advise offering the daily amount of food recommended for the
                  ideal bodyweight of your dog in the feeding guide on the pack
                </p>
              
              </div>
            </div>

         
          </div>
        </div>
      </section>

      <section className="feeding-top">
      <div className="blurvector-img">
                  <img src={ blurvector } alt="" className="blur-img-left" />
                  <img src={ blurvector2 } alt="" className="blur-img" />
                </div>
        <div className="container h5-style mt-5">
          <h5 className="">
            In the below guide you'll find the recommended daily calories for
            your dog, as well as feeding guidelines based on a diet of Pet Food.
            If you are feeding a different brand, please consult the calorie
            information on-pack and adjust the amount of food accordingly to
            meet your dog's recommended daily calories.
          </h5>
          <h5>Please remember:</h5>
          <div className="content mt-5">
            <p>
              {" "}
              To have the daily amount of food if you are feeding two meals a
              day.{" "}
            </p>
            <p>
              The guidelines are based on your dog getting the right amount of
              exercise. If your dog has less than 1 hour exercise a day, please
              reduce quantity by 10%{" "}
            </p>
            <p>
              {" "}
              The WalthamTM Centre for Pet Nutrition recommends feeding a mixed
              diet where 50% of calories come from wet food and 50% from dry
              food.{" "}
            </p>
            <p>
              {" "}
              The guidelines are based on all of your dog's recommended daily
              calories coming from 100% complete Dry or wet food. Please adjust
              accordingly if you are going to feed any oral care products or
              treats.{" "}
            </p>
            <p>
              {" "}
              Please ensure that any 'non-complete' treats or food you are
              feeding your dog accounts for less than 10% of your dog's daily
              calories.{" "}
            </p>
            {/* <div className="button-align">
              <a className="button-position" href="/">
                Click here for proper feeding guide
              </a>
            </div> */}
          </div>
        </div>
        <div className="form-bg"></div>
        <div className="form-bg-right"></div>
      </section>

      <div className="App">
        <table className="table-spacing p-2">
          <tr>
            <th>Dog size</th>
            <th>Dog's ideal body weight (kg)</th>
            <th>Daily calorie</th>
            <th>Mixed wet and dry diet</th>
            <th>100% wet diet</th>
            <th>100% dry</th>
          </tr>
          {data && data.map((val, key) => {
            return (
              <tr key={key}>
                <td>{val.PetSize}</td>
                <td>{val.IdealBody}</td>
                <td>{val.Calories}</td>
                <td>{val.MixedDiet}</td>
                <td>{val.WetDiet}</td>
                <td>{val.DryDiet}</td>
              </tr>
            );
          })}
        </table>
      </div>
      
      <Footer></Footer>
      <Scroll/>
    </>
  );
};

export default Feedingtable;
