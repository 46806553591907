import React from "react";
import { useState, useEffect } from "react";
import logo from "../images/ghmclogo 1.png";
import flowerlogo from "../images/Group 1320.png";
import vector from "../images/Mask Group 36.png";
import community from "../images/Group 109.png";
import "../Community/Community.css";
import Footer from "../Footer/Footer";
import Scroll from "../Backtotop/Scroll";
import { Communitycard } from "./Communitycard";
import blurvector from "../images/Mask Group 37.png";
import blurvector2 from "../images/Mask Group 38.png";
import "../Fonts/Fonts.css";
import blackdog from "../images/Group 24.png";
import flowerlogoup from "../images/Group1320up.png";
import location from "../images/map 1.png";
import connector from "../images/Group 1314.png";
import herodefault from "../images/hero-default.png";

export const Community = () => {
  const [data, setData] = useState();
  const [categorydata, setCategoryData] = useState();
  const Communityapi = async () => {
    const response = await fetch(
      `https://content.betterworldforpets.in/api/get/pet-community/info?_format=json`
    ).then((response) => response.json());

    setData(response);
  };

  const Communitylistapi = async () => {
    const response = await fetch(
      `https://content.betterworldforpets.in/api/get/pet-community/list/?_format=json&page=0`
    ).then((response) => response.json());

    setCategoryData(response);
  };
  useEffect(() => {
    Communityapi();
    Communitylistapi();
  }, []);

  return (
    <>
      <section>
        <div className="image-overlap">
          <img src={community} alt="" className="" />
        </div>
        <div className="vector-img">
          <img src={vector} alt="" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="ghmc-logo">
                {/* <img src=""  alt="ghmc logo" /> */}
              </div>
              <div className="f-logo-bg">
                <img src={flowerlogo} alt="ghmc logo" />
                <img src={flowerlogo} alt="ghmc logo" />
              </div>
              <div className="text-content">
                <h2>Pet</h2>
                <h1>{data && data[0].title}</h1>
                <p>{data && data[0].body}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5">
        <div className="blurvector-img">
          <img src={blurvector} alt="" className="blur-img-left" />
          <img src={blurvector2} alt="" className="blur-img" />
        </div>
        <div className="container">
          <div className="community-text">
            <h3
              dangerouslySetInnerHTML={{ __html: data && data[0].subtitle }}
            ></h3>
            <p
              dangerouslySetInnerHTML={{ __html: data && data[0].subtext }}
            ></p>
          </div>
          <div className="community-card-bg">
            <div className="row">
              {categorydata &&
                categorydata.map((e) => {
                  return (
                    <div className="col-md-4">
                      <Communitycard
                        imageURL={e.imageURL}
                        title={e.title}
                        shortDescription={e.shortDescription}
                        id={e.articleId}
                      />
                      <div className="grooming-button">
                        <a
                          href={`${window.location.origin}/Communityarticle/?${e.articleId}`}
                        >
                          <button type="button" className="btn readmore-btn">
                            Read More
                          </button>
                        </a>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="store-dogimg">
                <img src={blackdog} alt="" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="store-buy">
                <img src={flowerlogoup} alt="" className="img-fluid" />
                <h1>Utilize Our Pet Grooming Services</h1>
                <p>Click here for more info</p>
                <div className="feed-button">
                <a
                          href={`${window.location.origin}/Storelocator`}
                        >
                  <button type="button" className="btn" >
                    <img
                      src={location}
                      className="img-fluid location-icon"
                    ></img>
                    Store Locator
                  </button></a>
                </div>
                <div className="c-dog">
                  <img
                    src={connector}
                    alt=""
                    className="img-fluid connector"
                  ></img>
                  <img
                    src={flowerlogoup}
                    alt=""
                    className="img-fluid dogflowerlogo"
                  />
                  <img
                    src={herodefault}
                    alt=""
                    className="img-fluid hero-dog"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <Footer></Footer>
      <Scroll/>
    </>
  );
};
