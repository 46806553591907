import React from "react";
import { useState, useEffect } from "react";
import "../Tracking/Dogtracker.css";
import logo from "../images/ghmclogo 1.png";
import flowerlogo from "../images/Group 1320.png";
import FeedingDog from "../images/Group 112.png";
import vector from "../images/Mask Group 36.png";
import blackdog from "../images/Group 24.png";
import flowerlogoup from "../images/Group1320up.png";
import location from "../images/map 1.png";
import connector from "../images/Group 1314.png";
import herodefault from "../images/hero-default.png";
import Footer from "../Footer/Footer";
import Scroll from "../Backtotop/Scroll";
import blurvector from "../images/Mask Group 37.png";
import blurvector2 from "../images/Mask Group 38.png";

export const Dogtracker = () => {
  const [data, setData] = useState();
  const [Breed, setBreed] = useState();
  const [Gender, setGender] = useState();
  const [Neutered, setNeutered] = useState();
  const [Weight, setWeight] = useState();
  const [Measure, setMeasure] = useState();
  const [Date, setDate] = useState();

  const getApiData = async () => {
    const response = await fetch(
      `https://api.betterworldforpets.in/api/BreedDetails/GetBreedDetails?speciesId=1`
    ).then((response) => response.json());

    setData(response);
  };

  useEffect(() => {
    getApiData();
  }, []);

  const apiResult = data ? Object.values(data) : [];
  const finalResult = apiResult[0];

  const handleBreedChange = (event) => {
    setBreed(event.target.value);
  };
  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };
  const handleNeuteredChange = (event) => {
    setNeutered(event.target.value);
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };
  const handleWeighteChange = (event) => {
    setWeight(event.target.value);
  };
  const handleMeasureChange = (event) => {
    setMeasure(event.target.value);
  };

  const resetForm = () => {
    setBreed("");
    setGender("");
    setNeutered("");
    setDate("");
    setWeight("");
    setMeasure("");
    hidediv();
  };

  function calculateDogAge(Date) {
    {
      var dogYears = 7 * Date;
      return dogYears;
    }
  }

  function showDiv() {
    document.getElementById("welcomeDiv").style.display = "block";
  }
  function hidediv() {
    document.getElementById("welcomeDiv").style.display = "";
  }
  function finalCaluclation() {
    calculateDogAge(Date);
    showDiv();
  }

  return (
    <>
      <section>
        <div className="image-overlap">
          <img src={FeedingDog} alt="" className="" />
        </div>
        <div className="vector-img">
          <img src={vector} alt="" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="ghmc-logo">
                {/* <img src={logo} alt="ghmc logo" /> */}
              </div>
              <div className="f-logo-bg">
                <img src={flowerlogo} alt="ghmc logo" />
                <img src={flowerlogo} alt="ghmc logo" className="flower-logo" />
              </div>
              <div className="text-content">
                <h2>Dog Age </h2>
                {/* <hr className="text-border"></hr> */}
                <h1>Calculator</h1>
                <p>
                  If your dog was a human, how old would he be? Ever wonder how
                  old your dog is in human years? Not all breeds age alike, so
                  use this program to calculate your dog's "human" age.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="form-tracker">
        <div className="blurvector-img">
          <img src={blurvector} alt="" className="blur-img-left" />
          <img src={blurvector2} alt="" className="blur-img" />
        </div>
        <div className="container">
          <form className="row">
            <div className="col-md-8 feed-border">
              <div className="form-group">
                <label for="inputState">
                  What is Your pup's Breed <sup>*</sup>
                </label>
                <select
                  id="inputState"
                  className="form-control breed-form"
                  value={Breed}
                  onChange={handleBreedChange}
                >
                  <option value="" disabled selected>
                    Please Select Breed
                  </option>

                  {finalResult &&
                    finalResult.map((i) => (
                      <option key={i.BreedId} value={i.BreedName}>
                        {i.BreedName}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group">
                <label for="birthday">
                  Yours pup's age ?<sup>*</sup>
                </label>
                <input
                  type="number"
                  min="0"
                  max="18"
                  id="birthday"
                  name="birthday"
                  className="form-control breed-form"
                  value={Date}
                  onChange={handleDateChange}
                  placeholder="age in Years"
                />
              </div>
              <div className="form-group">
                <label for="formGroupExampleInput">
                  {" "}
                  Current Weight <sup>*</sup>
                </label>
                <input
                  type="number"
                  min="0"
                  className="form-control  ExampleInput "
                  id="formGroupExampleInput"
                  placeholder="weight"
                  value={Weight}
                  onChange={handleWeighteChange}
                />
                <select
                  id="inputState"
                  className="form-control ExampleInput"
                  value={Measure}
                  onChange={handleMeasureChange}
                >
                  <option value="" disabled selected>
                    value
                  </option>
                  <option>lbs</option>
                  <option>kg</option>
                </select>
              </div>

              <div className="form-group">
                <label for="genderinputState">
                  Gender of your pup ? <sup>*</sup>
                </label>
                <select
                  id="genderinputState"
                  className="form-control breed-form"
                  value={Gender}
                  onChange={handleGenderChange}
                >
                  <option value="" disabled selected>
                    Select Gender
                  </option>
                  <option>Male</option>
                  <option>Female</option>
                  <option>Unknown</option>
                </select>
              </div>
              <div className="form-group">
                <label for="neuteredinputState">
                  Is the dog neutered/spayed <sup>*</sup>
                </label>
                <select
                  id="neuteredgenderinputState"
                  className="form-control breed-form"
                  value={Neutered}
                  onChange={handleNeuteredChange}
                >
                  <option value="" disabled selected>
                    Please select option
                  </option>
                  <option>Yes</option>
                  <option>No</option>
                </select>
              </div>
              <div className="feed-button">
                <button
                  type="button"
                  className="btn"
                  onClick={() => resetForm()}
                >
                  Reset
                </button>
                <button
                  type="button"
                  className="btn"
                  onClick={() => finalCaluclation()}
                >
                  Calculate
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="form-bg"></div>
        <div className="form-bg-right"></div>
      </section>
      <section>
        <div className="answer-list-text mt-4">
          {Math.floor(Date) <= 1 ? (
            <h1 id="welcomeDiv" className="answer_list">
              In human years your dog is {calculateDogAge(Date)} and consider as
              puppy
            </h1>
          ) : (
            <h1 id="welcomeDiv" className="answer_list">
              In human years your dog is {calculateDogAge(Date)} and consider as
              Adult
            </h1>
          )}
        </div>
      </section>

      <section className="mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="store-dogimg">
                <img src={blackdog} alt="" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="store-buy">
                <img src={flowerlogoup} alt="" className="img-fluid" />
                <h1>Utilize Our Pet Grooming Services</h1>
                <p>Click here for more info</p>
                <div className="feed-button">
                <a
                          href={`${window.location.origin}/Storelocator`}
                        >
                  <button type="button" className="btn">
                    <img
                      src={location}
                      className="img-fluid location-icon"
                    ></img>
                    Store Locator
                  </button> </a>
                </div>
                <div className="c-dog">
                  <img
                    src={connector}
                    alt=""
                    className="img-fluid connector"
                  ></img>
                  <img
                    src={flowerlogoup}
                    alt=""
                    className="img-fluid dogflowerlogo"
                  />
                  <img
                    src={herodefault}
                    alt=""
                    className="img-fluid hero-dog"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Scroll />
      <Footer></Footer>
    </>
  );
};
