import React from "react";
import { useState, useEffect } from "react";
import logo from "../images/ghmclogo 1.png";
import flowerlogo from "../images/Group 1320.png";
import puppies from "../images/Group 91.png";
import obedience from "../images/trainingpets1.png";
import obedience1 from "../images/trainingpet2.png";
import obedience2 from "../images/trainingpet3.png";
import vector from "../images/Mask Group 36.png";
import blackdog from "../images/Group 24.png";
import flowerlogoup from "../images/Group1320up.png";
import location from "../images/map 1.png";
import connector from "../images/Group 1314.png";
import herodefault from "../images/hero-default.png";
import blurvector from "../images/Mask Group 37.png";
import blurvector2 from "../images/Mask Group 38.png";
import "../Pet-training/Training.css";
import Footer from "../Footer/Footer";
import Scroll from "../Backtotop/Scroll";

export const Training = () => {
  const [data, setData] = useState();
  const [categorydata, setCategoryData] = useState();

  const Trainingapi = async () => {
    const response = await fetch(
      `https://content.betterworldforpets.in/api/get/pet-training-puppie/info/?_format=json`
    ).then((response) => response.json());

    setData(response);
  };

  const TrainingCategoryapi = async () => {
    const response = await fetch(
      `https://content.betterworldforpets.in/api/get/pet-training-category/list?_format=json`
    ).then((response) => response.json());

    setCategoryData(response);
  };
  useEffect(() => {
    Trainingapi();
    TrainingCategoryapi();
  }, []);

  return (
    <>
      <section>
      <div className="image-overlap">
                <img src={puppies} alt="" className="" />
              </div>
              <div className="vector-img">
                  <img src={vector} alt="" />
                </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="ghmc-logo">
                {/* <img src={logo} alt="ghmc logo" /> */}
              </div>
              <div className="f-logo-bg">
                <img src={flowerlogo} alt="ghmc logo" />
                <img src={flowerlogo} alt="ghmc logo" />
              </div>
              <div className="text-content">
                <h2>Training & Behavior:</h2>
                {/* <hr className="text-border"></hr> */}
                <h1>{data && data[0].title}</h1>
                <p>{data && data[0].body}</p>
              
              </div>
            </div>

          </div>
        </div>
      </section>
      <section className="mt-5">
        <div className="blurvector-img">
          <img src={blurvector} alt="" className="" />
          <img src={blurvector2} alt="" className="blur-img" />
        </div>
        <div className="container">
          <div className="training-cards-bg">
            <div className="row">
              <div className="col-md-4">
                <div className="training-cards">
                  <img src={obedience} className="img-fluid"></img>
                  <h4>{categorydata && categorydata[0].categoryName}</h4>
                  <p>{categorydata && categorydata[0].categoryDescription}</p>
                  <div className="training-button">
                    <a
                       href={`${window.location.origin}/Trainingcategory/?${categorydata && categorydata[0].categoryId}`}
                    >
                      <button type="button" className="btn readmore-btn">
                        Learn More
                      </button>{" "}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="training-cards">
                  <img src={obedience1}></img>
                  <h4>{categorydata && categorydata[1].categoryName}</h4>
                  <p>{categorydata && categorydata[1].categoryDescription}</p>
                  <div className="training-button">
                  <a
                        href={`${window.location.origin}/Trainingcategory/?${categorydata && categorydata[1].categoryId}`}
                      >
                    <button type="button" className="btn readmore-btn">
                      Learn More
                    </button></a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="training-cards">
                  <img src={obedience2}></img>
                  <h4>{categorydata && categorydata[2].categoryName}</h4>
                  <p>{categorydata && categorydata[2].categoryDescription}</p>
                  <div className="training-button">
                  <a  href={`${window.location.origin}/Trainingcategory/?${categorydata && categorydata[2].categoryId}`}>
                    <button type="button" className="btn readmore-btn">
                      Learn More
                    </button></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="store-dogimg">
                <img src={blackdog} alt="" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="store-buy">
                <img src={flowerlogoup} alt="" className="img-fluid" />
                <h1>Utilize our Pet Gromming Services</h1>
                <p>click here for more info</p>
                <div className="feed-button">
                <a
                          href={`${window.location.origin}/Storelocator`}
                        >
                  <button type="button" className="btn">
                    <img
                      src={location}
                      className="img-fluid location-icon"
                    ></img>
                    Store Locator
                  </button> </a>
                </div>
                <div className="c-dog">
                  <img
                    src={connector}
                    alt=""
                    className="img-fluid connector"
                  ></img>
                  <img
                    src={flowerlogoup}
                    alt=""
                    className="img-fluid dogflowerlogo"
                  />
                  <img
                    src={herodefault}
                    alt=""
                    className="img-fluid hero-dog"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <Footer></Footer>
      <Scroll/>
    </>
  );
};
