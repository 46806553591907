import React from "react";
import { useState, useEffect } from "react";
import logo from "../images/ghmclogo 1.png";
import flowerlogo from "../images/Group 1320.png";
import greydog from "../images/greydog-6.png";
import vector from "../images/Mask Group 36.png";
import "./healthcare.css";
import calender from "../images/calender-icon.png";
import twodogs from "../images/dogs-content.png";
import rightclick from "../images/Vector Smart Object.png";
import { Healthcard } from "./Healthcard";
import Footer from "../Footer/Footer";
import Scroll from "../Backtotop/Scroll";
import blurvector from "../images/Mask Group 37.png";
import blurvector2 from "../images/Mask Group 38.png";

const Healthcare = () => {
  const [data, setData] = useState();
  const [categorydata, setHealthcareCategoryData] = useState();

  const Healthcareapi = async () => {
    const response = await fetch(
      `https://content.betterworldforpets.in/api/get/healthcare-info?_format=json`
    ).then((response) => response.json());

    setData(response);
  };

  const HealthcareCategoryapi = async () => {
    const response = await fetch(
      `https://content.betterworldforpets.in/api/get/healthcare-tips-list/?_format=json&page=0`
    ).then((response) => response.json());

    setHealthcareCategoryData(response);
  };
  useEffect(() => {
    Healthcareapi();
    HealthcareCategoryapi();
  }, []);

  return (
    <>
      <section>
        <div className="image-overlap">
          <img src={greydog} alt="" className="" />
        </div>
        <div className="vector-img">
          <img src={vector} alt="" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="ghmc-logo">
                {/* <img src={logo} alt="ghmc logo" /> */}
              </div>
              <div className="f-logo-bg">
                <img src={flowerlogo} alt="ghmc logo" />
                <img src={flowerlogo} alt="ghmc logo" />
              </div>
              <div className="text-content">
                <h2>Seasonal</h2>
                {/* <hr className="text-border"></hr> */}
                <h1
                  dangerouslySetInnerHTML={{ __html: data && data[0].title }}
                ></h1>
                <p
                  dangerouslySetInnerHTML={{ __html: data && data[0].body }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="blurvector-img">
          <img src={blurvector} alt="" className="blur-img-left" />
          <img src={blurvector2} alt="" className="blur-img" />
        </div>
        <div className="container">
          <div className="content-style">
            <h3>{data && data[0].subtitle}</h3>
            <p>{data && data[0].subtext}</p>
          </div>

          <div className="row">
            {categorydata &&
              categorydata.map((e) => {
                return (
                  <div className="col-md-4">
                    <Healthcard
                      imageURL={e.imageURL}
                      title={e.title}
                      shortDescription={e.shortDescription}
                      id={e.articleId}
                    />
                    <div className="grooming-button">
                      <a
                        href={`${window.location.origin}/Healtharticle/?${e.articleId}`}
                      >
                        <button type="button" className="btn readmore-btn">
                          Read More
                        </button>
                      </a>
                    </div>
                  </div>
                );
              })}
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="dogs-size">
                <img src={twodogs} alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 mt-5 rightclick">
              <h2>Seasonal Health Care Tips for Pets</h2>
              <div className="row">
                <div className="col-md-2 col-2">
                  <img src={rightclick} alt="" />
                </div>
                <div className="col-md-10 col-10">
                  <p>
                    Keep your pets inside as much as you can when the mercury
                    drops. If you have to take them out, stay outside with them.
                    When you're cold enough to go inside, they probably are too.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2 col-2">
                  <img src={rightclick} alt="" />
                </div>
                <div className="col-md-10 col-10">
                  <p>Spring Allergies Affect Your Pets Too</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2 col-2">
                  <img src={rightclick} alt="" />
                </div>
                <div className="col-md-10 col-10">
                  <p>Protect Pets From Summer Scorchers</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2 col-2">
                  <img src={rightclick} alt="" />
                </div>
                <div className="col-md-10 col-10">
                  <p>Help Your Pet Avoid Heatstroke in Summer & Fall</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2 col-2">
                  <img src={rightclick} alt="" />
                </div>
                <div className="col-md-10 col-10">
                  <p>
                    Outdoor kennels should be well-ventilated and in the shade
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Scroll />
      <Footer></Footer>
    </>
  );
};

export default Healthcare;
